import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Slider from "./js/Slider";
import MediaFilter from "./js/MediaFilter";
import Form from "./js/Form";
import Gallery from "./js/gallery";
import Masonry from "masonry-layout";
import eventBus from "./js/utils/eventBus";

import lazySizes from "lazysizes";
import GMap from "./js/GMap";
import Alpine from "alpinejs";
window.Alpine = Alpine;

Alpine.start();

import "./app.scss";

// barba.init({
//   debug: true,
//   logLevel: "error",
//   timeout: 5000,
// });

barba.hooks.beforeEnter((data) => {});

barba.hooks.after((data) => {
  runActions();
});

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

runActions();

function runActions() {
  action("Slider", Slider);
  action("MediaFilter", MediaFilter);
  action("GMap", GMap);
  action("Form", Form);
  action("Gallery", Gallery);
}

let msn;

function initMason() {
  if (!document.querySelector(".masonry")) return;
  msn?.destroy();
  msn = new Masonry(".masonry", {
    itemSelector: ".card-load",
  });
}
initMason();

eventBus.on("ajax-load", () => {
  initMason();
  action("Gallery", Gallery);
  // runActions();
});
