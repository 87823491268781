import { xor } from "lodash";
import eventBus from "./utils/eventBus";

export default function MediaFilter(el) {
  const buttons = el.querySelectorAll("form button");
  const listing = el.querySelector("[data-listing]");
  const loadMore = el.querySelector("[data-load-more]");

  let currentPage = +el.dataset.current;
  let currentTotal = +el.dataset.total;
  let currentCat = "";
  let totalPages = +el.dataset.totalPages;
  let cats = [];

  el.addEventListener("submit", (e) => {
    e.preventDefault();
  });

  buttons.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      currentPage = 1;
      currentCat = e.target.value;
      buttons[0].classList.remove("selected");

      if (btn.value == "") {
        buttons.forEach((btn) => btn.classList.remove("selected"));
        cats = [];
      } else {
        cats = xor(cats, [currentCat]);
      }

      btn.classList.toggle("selected");
      update();
    });
  });

  loadMore.addEventListener("click", (e) => {
    currentPage++;
    update();
  });

  function update() {
    listing.style.opacity = "0.5";

    const params = new URLSearchParams();
    params.append("page", currentPage);
    cats.forEach((cat) => params.append("cat[]", cat));
    console.log(params.toString());

    fetch(`?${params.toString()}`, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    }).then((r) => {
      currentPage = +r.headers.get("Currentpage");
      currentTotal = +r.headers.get("Total");
      totalPages = +r.headers.get("Totalpages");

      updateButton();

      const lastScroll = window.scrollY;

      r.text().then((html) => {
        listing.innerHTML = html;
        eventBus.emit("ajax-load");
        listing.style.opacity = "1";
        window.scrollTo(0, lastScroll);
      });
    });

    function updateButton() {
      if (currentPage > totalPages) {
        loadMore.classList.add("hidden");
      } else {
        loadMore.classList.remove("hidden");
      }
    }
  }
}
